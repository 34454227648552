import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoaderComponenet from '../components/common/Loader';

import { PrivateRoute } from './PrivateRoute';
import { useLocalStorage } from './useLocalStorage';


const NotFound = lazy(() => import('../components/common/404'));

const Apply = lazy(() => import('../components/Apply'));
const Register = lazy(() => import('../components/Register'));
const Attend = lazy(() => import('../components/Attend'));
const Welcome = lazy(() => import('../components/Welcome'));
const Login = lazy(() => import('../components/Login'));
const Dashboard = lazy(() => import('../components/Dashboard'));
const Questionnaire = lazy(() => import('../components/Questionnaire'));
const Admin = lazy(() => import('../components/Admin'));

const RouteSetup = () => {

  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/provider/:provider_id"
          element={
            <PrivateRoute>
              <Questionnaire />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <Admin />
          }
        />
        {/* <Route path="/" element={<Welcome />} /> */}
        {/* <Route path="/apply" element={<Apply />} /> */}
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route path="/attend" element={<Attend />} /> */}
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
